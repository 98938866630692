import DropDownMenu_SeparateShinyIMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/SeparateShinyIMenuButtonSkin/SeparateShinyIMenuButtonSkin.skin';
import DropDownMenu_SeparateShinyIMenuButtonSkinController from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_SeparateShinyIMenuButtonSkin = {
  component: DropDownMenu_SeparateShinyIMenuButtonSkinComponent,
  controller: DropDownMenu_SeparateShinyIMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_SeparateShinyIMenuButtonSkin']: DropDownMenu_SeparateShinyIMenuButtonSkin
};

